import React from "react";
import { Link } from "react-router-dom";
import "../global-styles.less";

export interface Props {
  links: any[];
}

export default function Header({ links }: Props) {
  return (
    <div className="global-styles-ui">
      <header>
        <div className="header-text">AcadeXellence</div>
        <div className="header-buttons">
          {links?.map((link: any) => (
            <Link to={link.to}>
              <button type="submit">{link.labelText}</button>
            </Link>
          ))}
        </div>
      </header>
    </div>
  );
}
