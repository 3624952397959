import React from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";

function ManageUser() {
  const navigate = useNavigate();
  return (
    <div className="amcpage_container">
      <div className="amcmain-container">
        <AdminNavbar />

        <div className="amccontent">
          <br />
          <div className="form">
            <form id="admin_form">
              <fieldset id="admc" className="nuser">
                <legend>Add a New User</legend>
                <br />
                <br />

                <label htmlFor="courseSelect">Role</label>
                <select id="courseSelect" name="course">
                  <option value=""></option>
                  <option value="">student</option>
                  <option value="">Instructor</option>
                  <option value="">PC</option>
                  <option value="">QAO</option>
                </select>
                <br />
                <br />
                <label htmlFor="firstName">First Name:</label>
                <input id="firstName" name="firstName" type="text" />
                <br />
                <br />
                <label htmlFor="lastName">Last Name:</label>
                <input id="lastName" name="lastName" type="text" />
                <br />
                <br />
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  name="email"
                  readOnly
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
                />
                <br />
                <br />
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  pattern="[0-9]{10}"
                />
                <br />
                <br />
              </fieldset>
              <br />
              <button type="submit">Add User</button>
            </form>
          </div>

          <div className="amctable">
            <table className="amcgrades-table">
              <thead>
                <tr className="amcgrades-table-row">
                  <th className="amcgrades-table-header">F_NAME</th>
                  <th className="amcgrades-table-header">L_NAME</th>
                  <th className="amcgrades-table-header">EMAIL</th>
                  <th className="amcgrades-table-header">PHONE NO.</th>
                  <th className="amcgrades-table-header">ROLE</th>
                  <th className="amcgrades-table-header">U_ID</th>
                  <th className="amcgrades-table-header">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr className="amcgrades-table-row">
                  <td>Aashiq</td>
                  <td>sayyed</td>
                  <td>aashiq@gmail.com</td>
                  <td>+6892345678</td>
                  <td>Instructor</td>
                  <td>1002484935</td>
                  <td>
                    <a href="">
                      <button
                        type="submit"
                        onClick={() => {
                          navigate("/homepage");
                        }}
                      >
                        Edit
                      </button>
                    </a>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Ruthwik</td>
                  <td>Reddi</td>
                  <td>rxk6773@gmail.com</td>
                  <td>+6892345678</td>
                  <td>Student</td>
                  <td>1002063535</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Anirudh</td>
                  <td>Khande Rao</td>
                  <td>axk6773@gmail.com</td>
                  <td>+6892345678</td>
                  <td>Program Coordinator</td>
                  <td>1002063535</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Ravi</td>
                  <td>Rajpurohit</td>
                  <td>axk6773@gmail.com</td>
                  <td>+6892345678</td>
                  <td>Instructor</td>
                  <td>1002063535</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default ManageUser;
