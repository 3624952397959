import React from "react";
import PCNavbar from "./PCNavbar";

function Collabs() {
  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <PCNavbar />

        <div className="right-content">
          <div className="PCgoals_container">
            <h2>Program Coordinator Collaboration</h2>
            <p>
              Program coordinators play a crucial role in fostering
              collaboration among various stakeholders within an academic
              institution. They work closely with students, instructors, and
              administrators to ensure the success and continuous improvement of
              the academic program.
            </p>
          </div>
          <br />
          <br />

          <div className="collaboration-box">
            <div className="collaboration-container">
              <h3>Collaboration with Students</h3>
              <p>
                Program coordinators engage with students to address their
                concerns, provide guidance, and create a supportive learning
                environment. They serve as a point of contact for students,
                helping them navigate their academic journey.
              </p>
            </div>
            <div className="collaboration-container">
              <h3>Collaboration with Instructors</h3>
              <p>
                Coordinators collaborate with instructors to develop course
                content, align learning objectives, and ensure effective
                teaching methods. They facilitate discussions on curriculum
                improvements and teaching strategies.
              </p>
            </div>
            <div className="collaboration-container">
              <h3>Collaboration with Administrators</h3>
              <p>
                Program coordinators work closely with administrators to make
                strategic decisions about program development and growth. They
                advocate for resources and support, ensuring the program's
                alignment with institutional goals.
              </p>
            </div>
          </div>
          <br />
          <br />

          <div className="lists-container colab-list-container">
            <h4>
              Displaying the student, Instructor, Admin details to collaborate
              with them
            </h4>
            <label htmlFor="listSelector">Select user</label>
            <div className="scrollable_container">
              <select id="listSelector">
                <option value="students">Students</option>
                <option value="instructors">Instructors</option>
                <option value="admins">Admins</option>
              </select>

              <table id="data-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Aashiq</td>
                    <td>Sayyed</td>
                    <td>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ahs@mavs.uta.edu">
                        ahs@mavs.uta.edu
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Ruthwik</td>
                    <td>Reddi</td>
                    <td>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=rkd5814@mavs.uta.edu">
                        rkd5814@mavs.uta.edu
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Anirudh sai</td>
                    <td>Khanderao</td>
                    <td>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=axk5886@mavs.uta.edu">
                        axk5886@mavs.uta.edu
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Rutva</td>
                    <td>Thaker</td>
                    <td>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=rtk5471@mavs.uta.edu">
                        rtk5471@mavs.uta.edu
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Ravi</td>
                    <td>Purohit</td>
                    <td>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=rap5112@mavs.uta.edu">
                        rap5112@mavs.uta.edu
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collabs;
