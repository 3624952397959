import React from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";

function StudentExams() {
  return (
    <div className="sepage-container">
      <div className="semain-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div className="secontent">
          <div className="button-container">
            <button id="examsButton">
              <Link to={studentRoutes.exams.to}>
                {studentRoutes.exams.labelText}
              </Link>
            </button>

            <button id="assignmentsButton">
              <Link to={studentRoutes.assignments.to}>
                {studentRoutes.assignments.labelText}
              </Link>
            </button>
          </div>

          <h2>Upcoming Exams</h2>
          <div id="exams" style={{ display: "flex" }}>
            <div className="exam-detail">
              <a href="instructions.pdf">
                <h3>Exam-1</h3>
                <ul>
                  <li>Course: XYZABCD</li>
                  <li>Date: 09/25/2023</li>
                  <li>Duration: 2 Hours</li>
                  <li>Maximum Points: 100</li>
                </ul>
              </a>
            </div>
            <div className="exam-detail">
              <a href="instructions.pdf">
                <h3>Exam-2</h3>
                <ul>
                  <li>Course: XYZABCD</li>
                  <li>Date: 10/10/2023</li>
                  <li>Duration: 1.5 Hours</li>
                  <li>Maximum Points: 50</li>
                </ul>
              </a>
            </div>
            <div className="exam-detail">
              <h3>Exam-3</h3>
              <a href="instructions.pdf">
                <ul>
                  <li>Course: XYZABCD</li>
                  <li>Date: 10/10/2023</li>
                  <li>Duration: 3 Hours</li>
                  <li>Maximum Points: 150</li>
                </ul>
              </a>
            </div>
            <div className="exam-detail">
              <h3>Exam-4</h3>
              <a href="instructions.pdf">
                <ul>
                  <li>Course: XYZABCD</li>
                  <li>Date: 10/10/2023</li>
                  <li>Duration: 1.5 Hours</li>
                  <li>Maximum Points: 75</li>
                </ul>
              </a>
            </div>
            <div className="exam-detail">
              <h3>Exam-5</h3>
              <a href="instructions.pdf">
                <ul>
                  <li>Course: XYZABCD</li>
                  <li>Date: 10/10/2023</li>
                  <li>Duration: 0.5 Hours</li>
                  <li>Maximum Points: 50</li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentExams;
