import React from "react";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import assignment from "../../assets/files/assignments.pdf";
import StudentNavbar from "./StudentNavbar";

export default function StudentAssignments() {
  return (
    <div className="sepage-container">
      <div className="semain-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div className="secontent">
          <div className="button-container">
            <button id="examsButton">
              <Link to={studentRoutes.exams.to}>
                {studentRoutes.exams.labelText}
              </Link>
            </button>

            <button id="assignmentsButton">
              <Link to={studentRoutes.assignments.to}>
                {studentRoutes.assignments.labelText}
              </Link>
            </button>
          </div>

          <div>
            <h2>Due Assignments</h2>
            <div id="assignments" style={{ display: "flex" }}>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Database Assignment</h3>
                  <ul>
                    <li>Due Date: 18th September</li>
                    <li>Maximum Points: 50</li>
                  </ul>
                </a>
              </div>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Cloud Computing Assignment</h3>
                  <ul>
                    <li>Due Date: 28th September</li>
                    <li>Maximum Points: 60</li>
                  </ul>
                </a>
              </div>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Database Assignment</h3>
                  <ul>
                    <li>Due Date: 10th October</li>
                    <li>Maximum Points: 50</li>
                  </ul>
                </a>
              </div>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Cloud Computing Assignment</h3>
                  <ul>
                    <li>Due Date: 20th September</li>
                    <li>Maximum Points: 60</li>
                  </ul>
                </a>
              </div>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Database Assignment</h3>
                  <ul>
                    <li>Due Date: 18th September</li>
                    <li>Maximum Points: 50</li>
                  </ul>
                </a>
              </div>
              <div className="assignment-detail">
                <a href={assignment} target="_blank" rel="noreferrer">
                  <h3>Cloud Computing Assignment</h3>
                  <ul>
                    <li>Due Date: 20th September</li>
                    <li>Maximum Points: 60</li>
                  </ul>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
