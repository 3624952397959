import React from "react";
import PCNavbar from "./PCNavbar";

function PCReports() {
  return (
    <div className="pcrpage_container">
      <div className="pcrmain-container">
        <PCNavbar />
        <div className="right-content">
          <div className="PC_container">
            <div className="card">
              <a href="#">
                <h2>Course 1</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>

            <div className="card">
              <a href="#">
                <h2>Course 2</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>
            <div className="card">
              <a href="#">
                <h2>Course 3</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>

            <div className="card">
              <a href="#">
                <h2>Course 4</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>
            <div className="card">
              <a href="#">
                <h2>Course 5</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>

            <div className="card">
              <a href="#">
                <h2>Course 6</h2>
                <p>Pass Percentage: 85%</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCReports;
