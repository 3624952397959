import React from "react";
import "./StudentsList.module.css";
import InstructorNavbar from "./InstructorNavbar";

export default function StudentsList() {
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar />

        <div className="student-container">
          <table>
            <tr>
              <th>S.No.</th>
              <th>Student</th>
              <th>Assignment</th>
              <th>Quiz</th>
              <th>Exam</th>
              <th>Total</th>
              <th>Grade</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Charlee Butler</td>
              <td>99</td>
              <td>49</td>
              <td>89</td>
              <td>237</td>
              <td>A</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Presley Perkins</td>
              <td>96</td>
              <td>50</td>
              <td>90</td>
              <td>236</td>
              <td>A</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Joe Swanson</td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Harmony Klein</td>
              <td>99</td>
              <td>49</td>
              <td>89</td>
              <td>237</td>
              <td>A</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Brian Wilson </td>
              <td>96</td>
              <td>50</td>
              <td>90</td>
              <td>236</td>
              <td>A</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Otto White</td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Luis Gray</td>
              <td>99</td>
              <td>49</td>
              <td>89</td>
              <td>237</td>
              <td>A</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Micah May</td>
              <td>96</td>
              <td>50</td>
              <td>90</td>
              <td>236</td>
              <td>A</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Raiden Hale</td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
            <tr>
              <td>10</td>
              <td>CTrinity Watson</td>
              <td>99</td>
              <td>49</td>
              <td>89</td>
              <td>237</td>
              <td>A</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Amelia Reed</td>
              <td>96</td>
              <td>50</td>
              <td>90</td>
              <td>236</td>
              <td>A</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Joe Swanson</td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Amina Ortiz</td>
              <td>99</td>
              <td>49</td>
              <td>89</td>
              <td>237</td>
              <td>A</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Manuel Barnes</td>
              <td>96</td>
              <td>50</td>
              <td>90</td>
              <td>236</td>
              <td>A</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Malcolm Horton</td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
            <tr>
              <td>16</td>
              <td>Asher Norman </td>
              <td>86</td>
              <td>44</td>
              <td>88</td>
              <td>218</td>
              <td>B</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="chat-icon">Chat?</div>
    </div>
  );
}
