import React from "react";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";

export default function DashboardCourseList() {
  return (
    <div className="page-container">
      <div className="main-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />

        <div className="content">
          <div className="course-box">
            <Link to="/course/1">
              Course 1<br />
              <br />
              <br />
              WEB DATA MANAGEMENT
            </Link>
          </div>
          <div className="course-box">
            <Link to="/course/2">
              Course 2<br />
              <br />
              <br />
              CLOUD COMPUTING
            </Link>
          </div>
          <div className="course-box">
            <Link to="/course/3">
              Course 3<br />
              <br />
              <br />
              SOFTWARE ENGINEERING
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
