import React from "react";
import "./StudentsList.module.css";
import InstructorNavbar from "./InstructorNavbar";

function InstructorAssignmentsList() {
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar />
        {/* MODULES BOXES */}

        <div>
          <button type="button" className="mark button primary-button">
            ADD ASSIGNMENT
          </button>
          <div className="modules-container">
            <table>
              <tr>
                <th>Assignment #1</th>
              </tr>
              <tr>
                <th>Assignment #2</th>
              </tr>
              <tr>
                <th>Assignment #3</th>
              </tr>
              <tr>
                <th>Quiz #1</th>
              </tr>
              <tr>
                <th>Assignment #4</th>
              </tr>
              <tr>
                <th>Assignment #5</th>
              </tr>
              <tr>
                <th>Quiz #2</th>
              </tr>
              <tr>
                <th>Quiz #3</th>
              </tr>
            </table>
          </div>
        </div>
        {/* CHAT */}
        <div className="chat-icon">Chat?</div>
      </div>
    </div>
  );
}

export default InstructorAssignmentsList;
