import React from "react";
import PCNavbar from "./PCNavbar";

function PCQueries() {
  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <PCNavbar />
        <div className="right-content">
          <div className="PC_container-card-grid">
            <div className="card-row-enquiries">
              <h4>
                Feedback forms submitted by Students are shown here. Feedback
                form can include course feedback and queries
              </h4>
              <div className="card-enquiries">
                <div className="student-info-enquiries">
                  <p>Student Name: xyz</p>
                  <p>Student ID: w5w6655s44</p>
                  <p>
                    Student Mail:{" "}
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hbe@abcd.efg.hij">
                      hbe@abcd.efg.hij
                    </a>
                  </p>
                </div>
              </div>
              <div className="card-enquiries">
                <div className="student-info-enquiries">
                  <p>Student Name: xyz</p>
                  <p>Student ID: w5w6655s44</p>
                  <p>
                    Student Mail:{" "}
                    <a href="mailto:hbe@abcd.efg.hij">hbe@abcd.efg.hij</a>
                  </p>
                </div>
              </div>
              {/* Add more card-enquiries here as needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCQueries;
