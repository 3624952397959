import React from "react";
import AdminNavbar from "./AdminNavbar";

function ManageUserActivities() {
  return (
    <div className="amcpage_container">
      <div className="amcmain-container">
        <AdminNavbar />
        <div className="amctable">
          <table className="amcgrades-table">
            <thead>
              <tr className="amcgrades-table-row">
                <th className="amcgrades-table-header">First Name</th>
                <th className="amcgrades-table-header">Last Name</th>
                <th className="amcgrades-table-header">Email</th>
                <th className="amcgrades-table-header">Username</th>
                <th className="amcgrades-table-header">User Type</th>
                <th className="amcgrades-table-header">Action</th>
                <th className="amcgrades-table-header">User Permission</th>
              </tr>
            </thead>
            <tbody>
              <tr className="amcgrades-table-row">
                <td>Ravi</td>
                <td>Rajpurohit</td>
                <td>rrp123@mavs.uta.edu</td>
                <td>rrp1</td>
                <td>Person</td>
                <td>
                  <a href="monitor.html">
                    <button type="submit">Monitor</button>
                  </a>
                </td>
                <td>
                  <input
                    type="radio"
                    name="option"
                    value="Option 1"
                    id="Option 1"
                  />
                  <label htmlFor="Option 1">
                    Allow permission to manage course
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="option"
                    value="Option 2"
                    id="Option 2"
                  />
                  <label htmlFor="Option 2">
                    Allow permission to manage program
                  </label>
                </td>
              </tr>
              <tr className="amcgrades-table-row">
                <td>Sai Ruthwik</td>
                <td>Reddy</td>
                <td>srr345@mavs.uta.edu</td>
                <td>srr3</td>
                <td>Instructor</td>
                <td>
                  <a href="monitor.html">
                    <button type="submit">Monitor</button>
                  </a>
                </td>
                <td>
                  <input
                    type="radio"
                    name="option"
                    value="Option 1"
                    id="Option 1"
                  />
                  <label htmlFor="Option 1">
                    Allow permission to manage course
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="option"
                    value="Option 2"
                    id="Option 2"
                  />
                  <label htmlFor="Option 2">
                    Allow permission to manage program
                  </label>
                </td>
              </tr>
              <tr className="amcgrades-table-row">
                <td>Anirudh</td>
                <td>Sai</td>
                <td>ans456@mavs.uta.edu</td>
                <td>ans4</td>
                <td>QA Officer</td>
                <td>
                  <a href="monitor.html">
                    <button type="submit">Monitor</button>
                  </a>
                </td>
                <td>
                  <input
                    type="radio"
                    name="option"
                    value="Option 1"
                    id="Option 1"
                  />
                  <label htmlFor="Option 1">
                    Allow permission to manage course
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="option"
                    value="Option 2"
                    id="Option 2"
                  />
                  <label htmlFor="Option 2">
                    Allow permission to manage program
                  </label>
                </td>
              </tr>
              <tr className="amcgrades-table-row">
                <td>Rutva</td>
                <td>Thekre</td>
                <td>rtt@mavs.uta.edu</td>
                <td>rtt5</td>
                <td>Program Coordinator</td>
                <td>
                  <a href="monitor.html">
                    <button type="submit">Monitor</button>
                  </a>
                </td>
                <td>
                  <input
                    type="radio"
                    name="option"
                    value="Option 1"
                    id="Option 1"
                  />
                  <label htmlFor="Option 1">
                    Allow permission to manage course
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="option"
                    value="Option 2"
                    id="Option 2"
                  />
                  <label htmlFor="Option 2">
                    Allow permission to manage program
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ManageUserActivities;
