import React from "react";
import { Link } from "react-router-dom";
import { instructorRoutes } from "../../constants";

export interface Props {
  isSubMenu?: boolean;
  wrapperClass: string;
  menuItemClass: string;
}

function InstructorNavbar() {
  console.log(Object.entries(instructorRoutes));
  return (
    <div className="left-navbar">
      {Object.values(instructorRoutes).map((route: any) => (
        <Link className="nav-button" to={route.to}>
          {route.labelText}
        </Link>
      ))}
    </div>
  );
}

export default InstructorNavbar;
