import React from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";

export interface Props {
  routes: Record<string, object>;
  isSubMenu?: boolean;
  wrapperClass: string;
  menuItemClass: string;
}

function CommonNavbar({
  isSubMenu,
  wrapperClass,
  menuItemClass,
  routes,
}: Props) {
  return (
    <div className="global-styles-ui">
      <div className={wrapperClass}>
        {!isSubMenu ? (
          <>
            {Object.entries(routes)
              .filter(
                ([key, _]: [string, any]) =>
                  key !== "subMenu" && key !== "assignments"
              )
              .map(([key, value]: [string, any]) => (
                <Link className={menuItemClass} to={value.to}>
                  {value.labelText}
                </Link>
              ))}
          </>
        ) : (
          <>
            {Object.entries(studentRoutes.subMenu).map(
              ([_, value]: [string, any]) => (
                <Link className={menuItemClass} to={value.to}>
                  {value.labelText}
                </Link>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CommonNavbar;
