import React from "react";
import Header from "../Header";
import { routes, studentRoutes } from "../../constants";
import "../../css/global-styles.css";
import covwdrk from "../../assets/covwdrk.jpg";
import { Link } from "react-router-dom";
import StudentNavbar from "./StudentNavbar";

export default function CourseDetail() {
  return (
    <div className="page-container">
      <div className="head">
        <Header links={[routes.logout]} />
      </div>
      <div className="c1main-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <StudentNavbar
            wrapperClass="left-navbar"
            menuItemClass="nav-button"
          />
          <StudentNavbar
            wrapperClass="c1right-navbar"
            menuItemClass="c1nav-button"
            isSubMenu
          />
          <div
            style={{
              width: "60%",
              background: " rgba(255, 255, 255, 0.5)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={covwdrk}
              alt="Course_overview"
              style={{ width: "60%", marginTop: 10 }}
            />
            <p style={{ color: "black", marginLeft: 20 }}>
              This course aims to provide Link comprehensive introduction to the
              world of web development. You'll start by learning the basics of
              HTML, CSS, and JavaScript, enabling you to build your first
              webpage. As you progress, we'll dive deeper into more advanced
              topics like responsive design, server-side programming, and
              database management. By the end of this course, you'll have all
              the skills you need to develop dynamic, user-friendly websites.
            </p>

            <p style={{ color: "black", marginLeft: 20 }}>
              Through Link series of interactive lectures and hands-on projects,
              you'll gain practical experience that will set you on the path to
              becoming Link full-stack developer. Whether you're Link beginner
              looking to break into the tech industry, or an experienced coder
              aiming to upskill, this course has something to offer.
            </p>

            <p style={{ color: "black", marginLeft: 20 }}>
              Key Takeaways:
              <ul>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Master the fundamentals of web development.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Learn best practices for coding and design.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Gain experience with popular development tools and frameworks.
                </li>
                <li style={{ color: "black", marginLeft: 20 }}>
                  Develop Link portfolio of projects to showcase your skills.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
