import React from "react";
import Header from "./Header";
import { routes } from "../constants";
import analysiis from "../assets/analysiis.jpeg";
import "../global-styles.less";
import selfPacedElearning from "../assets/self-paced-elearning.jpg";
import aboutusimg3 from "../assets/aboutusimg3.png";
import learningPath from "../assets/learning_path.jpg";
import study from "../assets/study.png";

function AboutUs() {
  return (
    <div className="global-styles-ui">
      <div className="pcrpage_container">
        <div className="head">
          <Header links={[routes.login, routes.signup, routes.home]} />
        </div>
        <div className="aboutus-aboutus-container">
          <h1>ABOUT US</h1>
          <div className="aboutus-main-container">
            <div className="aboutus-sub-container aboutus-equal-length">
              <img src={analysiis} alt="xyz" className="aboutus-left-image" />
              <div className="aboutus-right-text">
                <h3>Comprehensive Gradebook and In-depth Analytics</h3>
                <p>
                  The platform's integrated gradebook provides a holistic view
                  of student performance, enabling timely feedback and
                  transparent grade calculations. AcadeXellence's robust
                  analytics offer insights into course engagement, student
                  interactions, and performance metrics, assisting educators in
                  making informed instructional decisions.
                </p>
              </div>
            </div>
            <div className="aboutus-sub-container aboutus-equal-length aboutus-left-text">
              <img
                src={selfPacedElearning}
                alt="xyz"
                className="aboutus-right-image"
              />
              <div className="aboutus-right-text">
                <h3>Self-paced Learning Options</h3>
                <p>
                  Student Choice: AcadeXellence provides options for self-paced
                  learning, where students can progress through modules at their
                  own speed, ideal for asynchronous courses. Based on
                  performance, AcadeXellence can guide students through
                  personalized learning paths, offering remedial or advanced
                  materials as needed.
                </p>
              </div>
            </div>
            <div className="aboutus-sub-container aboutus-equal-length">
              <img src={aboutusimg3} alt="xyz" className="aboutus-left-image" />
              <div className="aboutus-right-text">
                <h3>Innovative Assessment Strategies</h3>
                <p>
                  AcadeXellence integrates a variety of assessment
                  methodologies, enabling instructors to measure student
                  understanding through quizzes, projects, peer reviews, and
                  reflective journals, thereby ensuring a comprehensive
                  evaluation of learning outcomes. AcadeXellence employs
                  sophisticated algorithms and data analytics to create
                  personalized learning journeys, adapting content and pacing to
                  individual learner needs, preferences, and performance, thus
                  maximizing educational attainment and retention. Intuitive
                  Course Management. AcadeXellence offers a seamless course
                  creation experience, enabling educators to structure lessons,
                  upload materials, and establish grading rubrics with ease.
                </p>
              </div>
            </div>
            <div className="aboutus-sub-container aboutus-equal-length aboutus-left-text">
              <img
                src={learningPath}
                alt="xyz"
                className="aboutus-right-image"
              />
              <div className="aboutus-right-text">
                <h3>Adaptive Learning Paths</h3>
                <p>
                  AcadeXellence integrates advanced adaptive learning
                  technologies to create personalized learning paths for
                  students. This adaptive approach enables students to focus on
                  areas that require improvement and progress at their own pace,
                  enhancing learning outcomes and fostering a sense of
                  achievement. Through AcadeXellence's adaptive learning paths,
                  students are empowered to take ownership of their learning
                  journey, building confidence and developing skills that will
                  serve them well in their academic and professional endeavors.
                </p>
              </div>
            </div>
            <div className="aboutus-sub-container aboutus-equal-length">
              <img src={study} alt="xyz" className="aboutus-left-image" />
              <div className="aboutus-right-text">
                <h3>Flexible Scheduling and Accessibility</h3>
                <p>
                  AcadeXellence recognizes the diverse needs and commitments of
                  today's learners, offering flexible scheduling and
                  accessibility features to accommodate various learning styles
                  and schedules. Students can access course materials, engage in
                  discussions, and complete assignments at their convenience,
                  removing the constraints of traditional classroom timings.
                  This flexibility ensures that education is inclusive and
                  adaptable, catering to the unique requirements of each
                  student. With user-friendly interfaces and adaptive
                  technologies, AcadeXellence ensures that students with
                  different abilities and needs can navigate the platform with
                  ease and participate fully in their educational journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
