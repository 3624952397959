import React from "react";
import InstructorNavbar from "./InstructorNavbar";
import pdf from "../../assets/files/modules.pdf";

function InstructorModules() {
  return (
    <div className="page-container">
      {/* MAIN CONTENT */}
      <div className="main-container">
        {/* REPLACE left-navbar WITH InstructorNavbar */}
        <InstructorNavbar />
        {/* MODULES BOXES */}

        <div>
          <button type="button" className="mark button primary-button">
            ADD MODULE
          </button>
          <div className="modules-container">
            <div className="module-detail">
              <a href={pdf}>
                <h3 id="hmodule">Module-1</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={pdf}>
                <h3 id="hmodule">Module-2</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={pdf}>
                <h3 id="hmodule">Module-3</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={pdf}>
                <h3 id="hmodule">Module-4</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={pdf}>
                <h3 id="hmodule">Module-5</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* CHAT */}
      <div className="chat-icon">Chat?</div>
    </div>
  );
}

export default InstructorModules;
