import React from "react";
import QANavbar from "./QANavbar";

function QAMonitor() {
  return (
    <div className="qamonpage_container">
      <div className="qamonmain-container">
        <QANavbar />
        <div className="sfcontent">
          <form className="feedback-form">
            <fieldset id="info" className="special-form">
              <legend>USER ACTIVITIES</legend>
              <p># Instructor-1 logged in at 10:00 AM.</p>
              <p>
                # Program Coordinator-2 accessed the "Manage Course" page at
                11:30 AM.
              </p>
              <p># Instructor-4 logged in at 10:00 AM.</p>
              <p>
                # Program Coordinator-2 edited Course-3 in "Msc in Computer
                Science".
              </p>
              <p># Instructor-1 logged in at 10:00 AM.</p>
              <p>
                # Program Coordinator-2 accessed the "Manage Course" page at
                11:30 AM.
              </p>
              <br />
            </fieldset>
            <br />
            <br />
          </form>
          <button type="submit">Troubleshoot</button>
          <br />
        </div>
      </div>
    </div>
  );
}

export default QAMonitor;
