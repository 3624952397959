import React from "react";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import "../../css/global-styles.css";
import syllabus from "../../assets/files/syllabus.pdf";
import StudentNavbar from "./StudentNavbar";

function Syllabus() {
  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar
          wrapperClass="c1left-navbar"
          menuItemClass="c1nav-button"
        />
        <StudentNavbar
          wrapperClass="c1right-navbar"
          menuItemClass="c1nav-button2"
          isSubMenu
        />

        <div className="c1content">
          <div className="syllabus">
            <h1 id="course2">Syllabus</h1>
            {/* Embedding PDF for syllabus */}
            <embed
              src={syllabus}
              type="application/pdf"
              width="100%"
              height="400px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Syllabus;
