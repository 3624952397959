import React from "react";
import QANavbar from "./QANavbar";

function QARecommendations() {
  return (
    <div className="qrecpage-container">
      <div className="qrecmain-container">
        <QANavbar />
        <div className="qreccontainer">
          <form>
            <fieldset id="info">
              <legend>Add Recommendations</legend>
              <br />
              <label htmlFor="instructorName">Instructor Name</label>
              <select id="instructorName" name="instructorName">
                <option value="instructor1"></option>
                <option value="instructor1">Instructor 1</option>
                <option value="instructor2">Instructor 2</option>
                <option value="instructor3">Instructor 3</option>
              </select>
              <br />
              <br />
              <label htmlFor="description">Recommendations:</label>
              <textarea
                id="description"
                name="address"
                rows={4}
                style={{ width: "auto" }}
              ></textarea>
              <br />
              <br />
            </fieldset>
            <br />
            <button type="submit">Submit</button>
          </form>
          <br />
          <br />
          <form className="feedback">
            <fieldset id="info" className="qaorec">
              <p>
                Here are recommendations for enhancing teaching methods,
                assessments, and program effectiveness:
              </p>
              <h3>Teaching Methods</h3>
              <ul>
                <li>Encourage interactive and participatory learning.</li>
                <li>Incorporate technology for engaging lessons.</li>
                <li>Regularly assess and adapt teaching strategies.</li>
              </ul>
              <h3>Assessments</h3>
              <p>To improve assessments:</p>
              <ol>
                <li>
                  <strong>Diversify Assessment Types:</strong> Include a mix of
                  quizzes, projects, and presentations.
                </li>
                <li>
                  <strong>Clear Assessment Criteria:</strong> Provide students
                  with transparent grading rubrics.
                </li>
                <li>
                  <strong>Feedback Loop:</strong> Establish a feedback mechanism
                  for ongoing improvement.
                </li>
              </ol>
              <h3>Program Effectiveness</h3>
              <p>For enhancing program effectiveness:</p>
              <ul>
                <li>Conduct regular program evaluations.</li>
                <li>Collect feedback from students and instructors.</li>
                <li>Review and update curriculum as needed.</li>
              </ul>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default QARecommendations;
