import React from "react";
import "../global-styles.less";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="global-styles-ui">
      <div className="foot actual-footer">
        <footer>
          <div className="footer-text">
            Copyrights @2023 AcadeXellence. All rights reserved.
            <Link to="/contactus">
              <button className="fp-contact-button">Contact Us</button>
            </Link>
            <Link to="/services">
              <button className="fp-service-button">Services</button>
            </Link>
          </div>
        </footer>
      </div>

      <div
        style={{
          opacity: 0,
          visibility: "hidden",
          background: "rgba(0,0,0,0)",
          padding: "6rem 2rem",
        }}
      ></div>
    </div>
  );
}
