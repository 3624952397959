import React from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import CommonNavbar from "../Shared/CommonNavbar";

export interface Props {
  isSubMenu?: boolean;
  wrapperClass: string;
  menuItemClass: string;
}

function StudentNavbar({ isSubMenu, wrapperClass, menuItemClass }: Props) {
  return (
    <CommonNavbar
      isSubMenu={isSubMenu}
      wrapperClass={wrapperClass}
      menuItemClass={menuItemClass}
      routes={studentRoutes}
    />
  );
}

export default StudentNavbar;
