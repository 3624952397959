import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import { routes } from "../../constants";

export default function Instructor() {
  return (
    <div className="instructor-ui head">
      <Header links={[routes.logout]} />
      <hr />
      <Outlet></Outlet>
    </div>
  );
}
