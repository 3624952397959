import React from "react";
import "../global-styles.less";
import Header from "./Header";
import { routes } from "../constants";
const links = [routes.home, routes.login, routes.signup];

const ForgotPassword = () => {
  return (
    <div className="pcrpage_container">
      <div className="head">
        <Header links={links} />
        <hr />
      </div>
      <div className="fp-container">
        <form className="fp-form">
          <br />
          <p className="fp-heading"><strong>Forgot Password</strong></p>
          <br />
          <br />

          <label htmlFor="Email">Email</label>
          <input
            type="text"
            id="Email"
            placeholder="Enter your email for verication"
            required
          />
          <br />
          <br />
          <label htmlFor="otp">Enter OTP</label>
          <input type="text" id="otp" placeholder="Enter OTP" required />
          <br />
          <br />
          <label htmlFor="newpwd">Enter New Password</label>
          <input type="text" id="newpwd" placeholder="Enter OTP" required />
          <br />
          <button className="reset-button" type="submit">
            Reset
          </button>
          <br />
          <br />
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
