import React from "react";
import CommonNavbar from "../Shared/CommonNavbar";
import { QARoutes } from "../../constants";

export default function QANavbar() {
  return (
    <CommonNavbar
      wrapperClass="left-navbar"
      menuItemClass="nav-button"
      routes={QARoutes}
      isSubMenu={false}
    />
  );
}
