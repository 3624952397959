import React from "react";
import InstructorNavbar from "./InstructorNavbar";
import "./StudentsList.module.css";

function InstructorExamsList() {
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar />

        <div>
          <button type="button" className="mark button primary-button">
            ADD EXAM
          </button>
          <div className="modules-container">
            <table>
              <tr>
                <th>Exam #1</th>
              </tr>
              <tr>
                <th>Exam #2</th>
              </tr>
              <tr>
                <th>Make-up Exam</th>
              </tr>
              <tr>
                <th>Final Exam</th>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div className="chat-icon">Chat?</div>
    </div>
  );
}

export default InstructorExamsList;
