import React from "react";
import CommonNavbar from "../Shared/CommonNavbar";
import { PCRoutes } from "../../constants";

export default function PCNavbar() {
  return (
    <CommonNavbar
      wrapperClass="left-navbar"
      menuItemClass="nav-button"
      routes={PCRoutes}
    />
  );
}
