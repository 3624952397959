import React from "react";
import StudentNavbar from "./StudentNavbar";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import "../../css/global-styles.css";

function StudentCourse() {
  return (
    <div className="scpage-container">
      <div className="scmain-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div className="sccontent">
          <div className="enrolled">
            <h2>Enrolled Courses</h2>
            <ul>
              <li>Data Structures - Prof. John</li>
              <li>Machine Learning - Prof. Emily</li>
              <li>Cloud Computing - Prof. Sarah</li>
            </ul>
          </div>
          <div className="newenroll">
            <h2>Enroll New Courses</h2>
            <small className="note">
              Select up to 3 new courses to enroll for next semester
            </small>
            <form id="newenrolled">
              <div className="custom-checkbox">
                <input
                  id="web_dev"
                  name="web_dev"
                  type="checkbox"
                  value="Web Development"
                />
                <label htmlFor="web_dev">Web Development</label>
              </div>
              <br />
              <div className="custom-checkbox">
                <input
                  id="cyber_security"
                  name="cyber_security"
                  type="checkbox"
                  value="Cyber Security"
                />
                <label htmlFor="cyber_security">Cyber Security</label>
              </div>
              <br />
              <div className="custom-checkbox">
                <input
                  id="big_data"
                  name="big_data"
                  type="checkbox"
                  value="Big Data"
                />
                <label htmlFor="big_data">Big Data</label>
              </div>
              <br />
              <div className="custom-checkbox">
                <input
                  id="ai"
                  name="ai"
                  type="checkbox"
                  value="Artificial Intelligence"
                />
                <label htmlFor="ai">Artificial Intelligence</label>
              </div>
              <br />
              <div className="custom-checkbox">
                <input
                  id="networking"
                  name="networking"
                  type="checkbox"
                  value="Networking"
                />
                <label htmlFor="networking">Networking</label>
              </div>
              <br />
              <input type="submit" value="Enroll Courses" />
            </form>
          </div>
        </div>
      </div>
      <div className="foot">
        <footer>Copyright © 2023 AcadeXellence. All rights reserved</footer>
      </div>
    </div>
  );
}

export default StudentCourse;
