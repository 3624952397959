import React from "react";
import QANavbar from "./QANavbar";

function QAManageCourse() {
  return (
    <div className="amcpage_container">
      <div className="amcmain-container">
        <QANavbar />
        <div className="amccontent">
          <br />
          <div className="form">
            <form id="admin_form">
              <fieldset id="admc">
                <legend>Add a New Course</legend>
                <br />
                <br />
                <label htmlFor="courseName">Course Name</label>
                <input type="text" id="courseName" name="courseName" required />
                <br />
                <br />
                <label htmlFor="courseObjective">Course Objective</label>
                <textarea
                  id="courseObjective"
                  name="courseObjective"
                  rows={8}
                  cols={40}
                ></textarea>
                <br />
                <br />
                <label htmlFor="instructorName">Instructor Name</label>
                <select id="instructorName" name="instructorName">
                  <option value="instructor1"></option>
                  <option value="instructor1">Instructor 1</option>
                  <option value="instructor2">Instructor 2</option>
                  <option value="instructor3">Instructor 3</option>
                </select>
                <br />
                <br />
                <label htmlFor="programName">Program Name</label>
                <select id="programName" name="programName">
                  <option value="mscComputerScience"></option>
                  <option value="mscComputerScience">
                    MSC in Computer Science
                  </option>
                  <option value="mscDataScience">MSC in Data Science</option>
                  <option value="mscAI">MSC in Artificial Intelligence</option>
                </select>
              </fieldset>
              <br />
              <button type="submit">Add Course</button>
            </form>
          </div>
          <div className="amctable">
            <table className="amcgrades-table">
              <thead>
                <tr className="amcgrades-table-row">
                  <th className="amcgrades-table-header">Course Name</th>
                  <th className="amcgrades-table-header">Course Objective</th>
                  <th className="amcgrades-table-header">Instructor Name</th>
                  <th className="amcgrades-table-header">Program Name</th>
                  <th className="amcgrades-table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="amcgrades-table-row">
                  <td>Course 1</td>
                  <td>Objective for Course 1</td>
                  <td>Instructor 1</td>
                  <td>MSC in Computer Science</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Course 2</td>
                  <td>Objective for Course 2</td>
                  <td>Instructor 2</td>
                  <td>MSC in Data Science</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Course 3</td>
                  <td>Objective for Course 3</td>
                  <td>Instructor 3</td>
                  <td>MSC in Artificial Intelligence</td>
                  <td>
                    <button type="submit">Edit</button>
                    <button type="submit">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default QAManageCourse;
