import React from "react";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import "../../css/global-styles.css";
import modules from "../../assets/files/modules.pdf";
import StudentNavbar from "./StudentNavbar";

function Modules() {
  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar
          wrapperClass="c1left-navbar"
          menuItemClass="c1nav-button"
        />
        <StudentNavbar
          wrapperClass="c1right-navbar"
          menuItemClass="c1nav-button2"
          isSubMenu
        />

        <div className="c1mcontent">
          <h1>Modules</h1>
          <div className="modules">
            <div className="module-detail">
              <a href={modules}>
                <h3 id="hmodule">Module-1</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={modules}>
                <h3 id="hmodule">Module-2</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={modules}>
                <h3 id="hmodule">Module-3</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={modules}>
                <h3 id="hmodule">Module-4</h3>
              </a>
            </div>
            <div className="module-detail">
              <a href={modules}>
                <h3 id="hmodule">Module-5</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modules;
