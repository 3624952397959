import React from "react";
import AdminNavbar from "./AdminNavbar";

function AdminChat() {
  return (
    <div className="chpage-container">
      <div className="chmain-container">
        <AdminNavbar />
        <div className="sccontent">
          <form className="feedback-form">
            <fieldset id="info">
              <legend>CHAT</legend>
              <select className="chatdropdown">
                <option value="option1">student</option>
                <a href="student.html">
                  <option value="option1">Instructor</option>
                </a>
                <option value="option2">Admin</option>
                <option value="option3">QA</option>
              </select>
              <br />
              <br />
              <input
                type="text"
                placeholder="Search..."
                className="chatsearch"
              />
              <br />
              <br />
              <textarea rows={4} cols={50} placeholder="Message..."></textarea>
              <br />
              <br />
              <button type="submit">Submit</button>
              <br />
              <br />
            </fieldset>
          </form>
          <br />
          <br />
          <div className="chat-container">
            <div className="old-chats-header">
              <b>
                <h3>Messages</h3>
              </b>
              <b>
                <p>X</p>
              </b>
            </div>
            <br />
            <input placeholder="Search..." className="convosearch" />
            <br />
            <br />
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar">
                  {/* You can add an image for the user's avatar here */}
                </div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar">
                  {/* You can add an image for the user's avatar here */}
                </div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar">
                  {/* You can add an image for the user's avatar here */}
                </div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminChat;
