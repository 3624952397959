import React from "react";
import "../global-styles.less";
import Header from "./Header";
import { routes } from "../constants";

function Services() {
  return (
    <div className="global-styles-ui">
      <div className="pcrpage_container">
        <div className="head">
          <Header links={[routes.login, routes.signup, routes.home]} />
        </div>
        <section className="ser-service-description">
          <h2>Responsive Web Design</h2>
          <p>
            Responsive web design is essential for providing a consistent and
            user-friendly experience across different devices and screen sizes.
            Buttons, links, and other interactive elements are easy to tap on
            touchscreen devices. Text remains readable without zooming, even on
            smaller screens. Images scale and resize within their containing
            elements when resized.
          </p>
        </section>

        <section className="ser-service-description">
          <h2>E-commerce Solutions</h2>
          <p>
            In today's digital age, e-commerce has transformed the way
            businesses operate, offering unparalleled opportunities to scale and
            cater to a global audience. Our e-commerce solutions are
            meticulously crafted to serve this evolving landscape. By
            integrating cutting-edge technologies and user-centric designs, we
            bring forth platforms that not only look visually appealing but also
            perform seamlessly. Experience the future of a digital education
            platform with us.
          </p>
        </section>

        <section className="ser-service-description">
          <h2>Website Redesign</h2>
          <p>
            As the internet evolves, so do user expectations and industry
            standards. We understand that your website is more than just a
            digital address. Our approach to the website is holistic. With this
            knowledge, we craft a design that is not only visually captivating
            but also strategically aligned with your business goals. By
            incorporating the latest technologies, we ensure your website loads
            faster and offers an intuitive user experience. Features like
            responsive screens, clear call-to-actions, and engaging content
            layouts are all integral to turning casual visitors into loyal
            customers.
          </p>
        </section>

        <section className="ser-service-description">
          <h2>Cloud-based</h2>
          <p>
            Being a cloud-based system, Canvas ensures that all data is backed
            up, and users can access the platform from anywhere with an internet
            connection.
          </p>
        </section>

        <section className="ser-service-description">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or would like to discuss your project,
            feel free to contact us:
          </p>
          <ul>
            <li>
              Email:{" "}
              <a href="mailto:axk5886@acadexellence.com">
                axk5886@acadexellence.com
              </a>
            </li>
            <li>
              Phone: <a href="tel:+1234567890">+1 682-(373)-1066</a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default Services;
