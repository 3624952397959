import React from "react";
import PCNavbar from "./PCNavbar";

function PCGoals() {
  return (
    <div className="pcrpage_container">
      <div className="pcgoals-main-container">
        <PCNavbar />
        <div className="pcgoals_container">
          <div className="program-goals">
            <h2>Program Goals</h2>
            <p>
              Graduate-level coursework in computer science equips students for
              careers in research and professional computing. The program covers
              both fundamental and advanced material in the fields of databases
              and artificial intelligence, systems and networks, theoretical
              computer science, software engineering, programming languages, and
              visual computing. Graduate classes are split into two categories:
              basic classes, which don't require any prior graduate coursework,
              and advanced classes, which do.
            </p>
            <br />
            <br />
            <ol>
              <li>Understand the Program's Goals</li>
              <li>Identify Key Administrators</li>
              <li>Establish Clear Communication Channels</li>
              <li>Build Trust and Rapport</li>
              <li>Gather Data and Information</li>
              <li>Prepare for Meetings</li>
              <li>Present Strategic Options</li>
              <li>Listen Actively</li>
              <li>Prioritize Long-Term Sustainability</li>
              <li>Create Action Plans</li>
              <li>Monitor Progress</li>
              <li>Measure Impact</li>
              <li>Adapt and Evolve</li>
              <li>Celebrate Achievements</li>
              <li>Document Everything</li>
              <li>Seek Professional Development</li>
            </ol>
          </div>
          <div className="program-goals-container">
            <button className="add-button">Add</button>
            <button className="delete-button">Delete</button>
            <h2>Program Goals for MS in Computer Science</h2>
            <p>
              The Master of Science (MS) in Computer Science program aims to
              achieve the following goals:
            </p>
            <ul>
              <li>
                Provide students with a deep and comprehensive understanding of
                computer science principles and theories.
              </li>
              <li>
                Prepare students for advanced research and development in
                various areas of computer science.
              </li>
              <li>
                Equip students with the necessary skills to solve complex
                computational problems and develop innovative solutions.
              </li>
              <li>
                Foster a collaborative and interdisciplinary learning
                environment that encourages creativity and critical thinking.
              </li>
              <li>
                Promote ethical and responsible computing practices among
                students.
              </li>
              <li>
                Offer specialized tracks or concentrations to allow students to
                tailor their education to their career goals.
              </li>
              <li>
                Facilitate opportunities for students to engage in research
                projects and publish their work.
              </li>
              <li>
                Provide access to state-of-the-art computing resources and
                laboratories.
              </li>
              <li>
                Offer career development and networking opportunities to enhance
                students' job prospects.
              </li>
              <li>
                Continuously update the curriculum to stay current with emerging
                trends in computer science.
              </li>
            </ul>
          </div>
          <div className="institution-goals">
            <h2>Goals of the Institution</h2>
            <p>
              Our objective is to provide students with the vocabulary,
              theoretical knowledge, and technical know-how necessary to
              comprehend and take part in current multidisciplinary research,
              particularly that which focuses on issues related to language,
              information, and intelligence—both human and artificial.
            </p>
          </div>
          <div className="department-vision">
            <h2>Vision of CS Department</h2>
            <p>
              The vision of the Computer Science department is to be a leader in
              cutting-edge research, innovation, and education in the field of
              computer science. We aim to prepare students to excel in a rapidly
              evolving technological landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCGoals;
