import React from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";

function StudentDiscussion() {
  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar
          wrapperClass="c1left-navbar"
          menuItemClass="c1nav-button"
        />
        <StudentNavbar
          wrapperClass="c1right-navbar"
          menuItemClass="c1nav-button2"
          isSubMenu
        />

        <div className="c1content">
          <div className="searchcontent">
            <form className="feedback-form">
              <fieldset id="info">
                <legend>CHAT</legend>
                <select id="description" className="chatdropdown">
                  <option value="option1">student</option>
                  <option value="option1">Instructor</option>
                  <option value="option2">Admin</option>
                  <option value="option3">QA</option>
                </select>
                <br />
                <br />
                <input
                  id="description"
                  type="text"
                  placeholder="Search..."
                  className="chatsearch"
                />
                <br />
                <br />
                <textarea
                  id="description"
                  rows={4}
                  cols={50}
                  placeholder="Message..."
                ></textarea>
                <br />
                <br />
                <button type="submit">Submit</button>
                <br />
                <br />
              </fieldset>
            </form>
          </div>
          <br />
          <br />
          <div className="chat-container">
            <div className="old-chats-header">
              <b>
                <h3>Messages</h3>
              </b>
              <b>
                <p>X</p>
              </b>
            </div>
            <br />
            <input placeholder="Search..." className="convosearch" />
            <br />
            <br />
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar"></div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar"></div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
            <div className="chat-template">
              <div className="chat-header">
                <div className="user-avatar"></div>
                <div className="user-info">
                  <h3>John Doe</h3>
                  <span>2 hours ago</span>
                </div>
              </div>
              <div className="chat-content">
                <p>Hello there! This is a LinkedIn-style message box.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDiscussion;
