import React from "react";
import "../css/global-styles.css";
import { Link } from "react-router-dom";
import { routes } from "../constants";
import Header from "./Header";

const links = [routes.login, routes.home];
function Signup() {
  return (
    <div className="global-styles-ui">
      <div className="pcrpage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>
        <div className="signup-container">
          <form>
            <fieldset id="info" className="filldetails for_phone">
              <legend>SIGN UP</legend>
              <label htmlFor="username">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username
              </label>
              <input
                type="text"
                id="username"
                placeholder="Enter your username"
                required
              />
              <br />
              <br />
              <label htmlFor="email">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mail&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <input
                type="text"
                id="email"
                placeholder="Enter your mailID"
                required
              />
              <br />
              <br />
              <label htmlFor="Password">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Password
              </label>
              <input
                type="password"
                id="Password"
                placeholder="Choose your Password"
                required
              />
              <br />
              <br />
              <label htmlFor="Confirm_password">Confirm Password</label>
              <input
                type="password"
                id="Confirm_password"
                placeholder="Re-enter your Password"
                required
              />
              <br />
              <br />
              <label htmlFor="roleselection">Select your Category</label>
              <select
                id="roleselection"
                className="signselect"
                name="Select your Category"
              >
                <option value="option1">Student</option>
                <option value="option2">Instructor</option>
                <option value="option3">Admin</option>
                <option value="option4">Quality Assurance Officer</option>
                <option value="option5">Program Coordinator</option>
              </select>
              <br />
              <br />
              <div className="account-info-signup">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Already have an account?</p>
                <Link to="/">Login</Link>
              </div>
            </fieldset>
            <br />
            <br />
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
