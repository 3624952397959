import React, { useState } from "react";
import "../global-styles.less";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { routes } from "../constants";
const links = [routes.home, routes.login, routes.signup];

function Login() {
  const navigate = useNavigate();
  return (
    <div className="global-styles-ui">
      {" "}
      <div className="pcrpage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>
        <div className="login-container">
          <form>
            <fieldset id="info" className="filldetails for_phone">
              <legend>LOGIN</legend>
              <br />
              <label htmlFor="username">U_ID &nbsp;&nbsp;&nbsp;</label>
              <input
                type="text"
                id="username"
                placeholder="Enter your username"
                required
              />
              <br />
              <br />
              <label htmlFor="Password">Password</label>
              <input
                type="password"
                id="Password"
                placeholder="Enter your Password"
                required
              />
              <br />
              <div className="remember-me">
                <input type="checkbox" id="remember" />
                <label htmlFor="remember">Remember me</label>
              </div>
              <br />
              <a>
                <p
                  className="forgot-password"
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                >
                  Forgot password?
                </p>
              </a>
              <br />
            </fieldset>
            <br />
            {/* <button type="submit">Login</button> */}
            <LoginButton />
          </form>
        </div>
      </div>
    </div>
  );
}

const LoginButton = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(">");
  const navigateTo = useNavigate();

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    if (option !== ">") navigateTo(`/${option}`);
  };

  return (
    <div className="login-button-container">
      <button
        type="submit"
        className="my-button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {selectedOption ? `Login as ${selectedOption}` : "Login as"}
      </button>
      {dropdownOpen && (
        <div className="dropdown">
          <div className="option" onClick={() => handleOptionClick("student")}>
            Login as Student
          </div>
          <div
            className="option"
            onClick={() => handleOptionClick("instructor")}
          >
            Login as Instructor
          </div>
          <div className="option" onClick={() => handleOptionClick("admin")}>
            Login as Admin
          </div>
          <div className="option" onClick={() => handleOptionClick("qa")}>
            Login as QA
          </div>
          <div className="option" onClick={() => handleOptionClick("pc")}>
            Login as PC
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
