import React from "react";
import QANavbar from "./QANavbar";

function QADashboard() {
  return (
    <div className="qapage-container">
      <div className="amcmain-container">
        <QANavbar />
        {/* MAIN CONTENT = NO HEADER AND FOOTER */}
        <div className="amccontent">
          <br />

          <div className="form">
            <form id="admin_form">
              <fieldset id="admc">
                <legend>Add a New Program/Policy</legend>
                <br />
                <br />
                <label htmlFor="instructorName">Select Program/Policy</label>
                <select id="instructorName" name="instructorName">
                  <option value="instructor1"></option>
                  <option value="instructor1">Program</option>
                  <option value="instructor2">Policy</option>
                </select>
                <br />
                <br />
                <label htmlFor="Name"> NAME</label>
                <input
                  type="text"
                  id="programName"
                  name="programName"
                  required
                />
                <br />
                <br />

                <label htmlFor="description">OBJECTIVE</label>
                <textarea
                  id="description"
                  name="objective"
                  rows={4}
                  required
                ></textarea>
                <br />
                <br />

                <label htmlFor="description">DESCRIPTION</label>
                <textarea
                  id="description"
                  name="description"
                  rows={4}
                  required
                ></textarea>
                <br />
                <br />
              </fieldset>
              <br />
              <button type="submit">Add Course</button>
            </form>
          </div>

          <div className="amctable">
            <table className="amcgrades-table">
              <thead>
                <tr className="amcgrades-table-row">
                  <th className="amcgrades-table-header">Program Name</th>
                  <th className="amcgrades-table-header">Program Objective</th>
                  <th className="amcgrades-table-header">
                    Program Description
                  </th>
                  <th className="amcgrades-table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="amcgrades-table-row">
                  <td>MSCS</td>
                  <td>Specific</td>
                  <td>A program that covers Particular topic of CS.</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>MSCS - AI</td>
                  <td>Master in Advanced AI</td>
                  <td>Focuses on AI, ML technologies.</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>MSCS - CyberSecurity</td>
                  <td>Develop CyberSecurity Expertise</td>
                  <td>Prepares students for careers in cybersecurity.</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="amcgrades-table">
              <thead>
                <tr className="amcgrades-table-row">
                  <th className="amcgrades-table-header">Policy Name</th>
                  <th className="amcgrades-table-header">Policy Objective</th>
                  <th className="amcgrades-table-header">Instructor Name</th>
                  <th className="amcgrades-table-header">Program Name</th>
                  <th className="amcgrades-table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="amcgrades-table-row">
                  <td>Policy-1</td>
                  <td>Objective for Course 1</td>
                  <td>Instructor 1</td>
                  <td>MSC in Computer Science</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Policy-2</td>
                  <td>Objective for Course 2</td>
                  <td>Instructor 2</td>
                  <td>MSC in Data Science</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
                <tr className="amcgrades-table-row">
                  <td>Policy-3</td>
                  <td>Objective for Course 3</td>
                  <td>Instructor 3</td>
                  <td>MSC in Artificial Intelligence</td>
                  <td>
                    <button type="submit">Edit</button>{" "}
                    <button type="submit">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QADashboard;
