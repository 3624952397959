import React from "react";
import InstructorNavbar from "./InstructorNavbar";
import "./StudentsList.module.css";

function InstructorFeedbackList() {
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar />
        <div>
          <button type="button" className="mark button primary-button">
            ADD FEEDBACK
          </button>
          <div className="feedback-container">
            <table style={{ width: "100%;" }}>
              <tr>
                <th>S.No.</th>
                <th>Student</th>
                <th>Feedback</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Charlee Butler</td>
                <td>Student feedback here</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Presley Perkins</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>3</td>
                <td>Joe Swanson</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>4</td>
                <td>Harmony Klein</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>5</td>
                <td>Brian Wilson </td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>6</td>
                <td>Otto White</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>7</td>
                <td>Luis Gray</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>8</td>
                <td>Charlee Butler</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>9</td>
                <td>Presley Perkins</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>10</td>
                <td>Joe Swanson</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>11</td>
                <td>Harmony Klein</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>12</td>
                <td>Brian Wilson </td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>13</td>
                <td>Otto White</td>
                <td>Student feedback here</td>{" "}
              </tr>
              <tr>
                <td>14</td>
                <td>Luis Gray</td>
                <td>Student feedback here</td>{" "}
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div className="chat-icon">Chat?</div>
    </div>
  );
}

export default InstructorFeedbackList;
