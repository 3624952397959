import React from "react";
import CommonNavbar from "../Shared/CommonNavbar";
import { adminRoutes } from "../../constants";

export default function AdminNavbar() {
  return (
    <CommonNavbar
      wrapperClass="left-navbar"
      menuItemClass="nav-button"
      routes={adminRoutes}
    />
  );
}
